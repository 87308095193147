import React, { useState, useRef, useEffect } from "react";
import cornerupLogo from "../../assets/cornerup-logo.png";
import quickbooksConnectButton from "../../assets/quickbooks-connect-button.png";

const LoginScreen = () => {
  const quickbooksAppURL = process.env.REACT_APP_URL;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const searchParams = new URLSearchParams(document.location.search);
  const sellerId = searchParams.get("sellerId");

  const modalRef = useRef();

  const myHeaders = new Headers();
  /*myHeaders.append(
    "API_KEY",
    "MSIPXNJGUOETFVREYDHFXOZVAAXZTUEFLZCFSFUIDDJFFQHCZVNTQPHUPVCMHCGWZMOFJFIAWDIPZLJUOGKJIJNPGCXSCLBDBFUKOCQTETYKUYPEICVNHTKHENURYGBD"
  );*/

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const getAuthorizeURL = async () => {
    const response = await fetch(
      `${quickbooksAppURL}/connect?seller_id=${sellerId}`,
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    return data;
  };

  const openModal = async () => {
    const authURL = await getAuthorizeURL();
    //console.log("authURL", authURL);
    setModalContent(authURL);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleIframeMessage = (event) => {
    //console.log("dentro de handleIframeMessage", event);
    if (event?.data === "ok") {
      closeModal();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isModalOpen && (
        <div className="register-modal-container">
          <div className="register-modal-content-container" ref={modalRef}>
            <iframe
              className="register-modal-content"
              title="Authorization"
              src={modalContent}
            />
            <button
              className="register-modal-close-button"
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </div>
      )}
      <div className="info-container">
        <a href="http://developer.intuit.com">
          <img alt="cornerup-logo" src={cornerupLogo} />
        </a>
        <hr className="logo-divider" />
        <div className="application-box">
          <h1 className="application-box-title">
            CornerUp QuickBooks application
          </h1>
          <br />
        </div>
        <h2 className="login-title">Login</h2>
        <p className="login-subtitle">
          Please <strong>Connect to QuickBooks</strong> button below.
        </p>
        <button onClick={openModal} className="connect-to-quickbooks-button">
          <img
            alt="connect-to-quickbooks"
            src={quickbooksConnectButton}
            className="connect-to-quickbooks-button-image"
          />
        </button>
      </div>
    </div>
  );
};

export default LoginScreen;
